<template>
  <div class="container">
    <h1 class="firstTitle">
      MENTIONS LÉGALES ET CONDITIONS GÉNÉRALES DE VENTE
    </h1>
    <span class="italic">En vigueur au 23/11/2022</span>
    <p>
      Pour toute question concernant nos mentions légales et conditions
      générales de vente, veuillez nous contacter par téléphone au +33 6 27 95
      56 64 ou par e-mail à l'adresse contact@sunjewelry.fr pour que nous
      puissions répondre à vos questions.
    </p>
    <h2>Mentions légales</h2>
    <p>
      Le Site www.sunjewelry.fr est édité par Typhen LE ROMANCER - SUN
      Jewelry,Auto-entreprise immatriculée sous le N°SIRET 90756792900027, ayant
      son siège social au 2 rue de l'oiselière 69009 Lyon.
    </p>
    <p>
      L'hébergement technique du Site est assuré par la société OVH, SAS, 2 rue
      Kellermann, 59100 Roubaix.
    </p>
    <p>Le Site a été réalisé par Louis LE POGAM</p>
    <h2>CONDITIONS GÉNÉRALES DE VENTE</h2>
    <h3>ARTICLE 1 - Champ d'application</h3>
    <p>
      Les présentes Conditions Générales de Vente (dites « CGV ») s'appliquent,
      sans restriction ni réserve à l'ensemble des ventes conclues par le
      Vendeur auprès d'acheteurs non professionnels (« Les Clients ou le Client
      »), désirant acquérir les produits proposés à la vente (« Les Produits »)
      par le Vendeur sur le site www.sunjewelry.fr. Le Vendeur a pour activité
      la vente de bijoux.
    </p>
    <p>
      Les caractéristiques principales des Produits et notamment les
      spécifications, illustrations et indications de dimensions ou de capacité
      des Produits, sont présentées sur le site www.sunjewelry.fr ce dont le
      client est tenu de prendre connaissance avant de commander.
    </p>
    <p>
      Le choix et l'achat d'un Produit sont de la seule responsabilité du
      Client.
    </p>
    <p>
      Les offres de Produits s'entendent dans la limite des stocks disponibles,
      tels que précisés lors de la passation de la commande.
    </p>
    <p>
      Ces CGV sont accessibles à tout moment sur le site www.sunjewelry.fr et
      prévaudront sur tout autre document.
    </p>
    <p>
      Le Client déclare avoir pris connaissance des présentes CGV et les avoir
      acceptées en cochant la case prévue à cet effet avant la mise en œuvre de
      la procédure de commande en ligne du site www.sunjewelry.fr.
    </p>
    <p>
      Sauf preuve contraire, les données enregistrées dans le système
      informatique du Vendeur constituent la preuve de l'ensemble des
      transactions conclues avec le Client.
    </p>
    <p>Les coordonnées du Vendeur sont les suivantes :</p>
    <p>
      Typhen LE ROMANCER - SUN Jewelry ,Auto-entreprise immatriculée sous le
      N°SIRET 90756792900027, ayant son siège social au 2 rue de l'oiselière
      69009 Lyon.
    </p>
    <p>e-mail : contact@sunjewelry.fr</p>
    <p>téléphone : +33 6 27 95 56 64</p>
    <p>
      Les Produits présentés sur le site www.sunjewelry.fr sont proposés à la
      vente pour tous les pays du monde.
    </p>
    <p>
      En cas de commande vers un pays autre que la France métropolitaine, le
      Client est l'importateur du ou des Produits concernés.
    </p>
    <h3>ARTICLE 2 - Prix</h3>
    <p>
      Les Produits sont fournis aux tarifs en vigueur figurant sur le site
      www.sunjewelry.fr, lors de l'enregistrement de la commande par le Vendeur.
    </p>
    <p>
      Les tarifs tiennent compte d'éventuelles réductions qui seraient
      consenties par le Vendeur sur le site www.sunjewelry.fr.
    </p>
    <p>
      Ces tarifs sont fermes et non révisables pendant leur période de validité
      mais le Vendeur se réserve le droit, hors période de validité, d'en
      modifier les prix à tout moment.
    </p>
    <p>
      Les prix ne comprennent pas les frais de traitement, d'expédition, de
      transport et de livraison, qui sont facturés en supplément, dans les
      conditions indiquées sur le site et calculés préalablement à la passation
      de la commande et intégrés au montant total de l'achat.
    </p>
    <p>
      Les informations et notamment les prix qui sont affichés sur le Site
      peuvent être erronés en raison d'erreurs de système ou de remplissage
      typographique. Si tout notre possible est fait pour éviter ce genre
      d'erreurs, elles peuvent toutefois exister. Le Vendeur n'honora pas les
      commandes issues d'informations ou prix erronés. Si le prix affiché est
      inférieur au prix réel du produit, le Vendeur contactera le Client par
      email pour soit annuler la commande ou lui donner les instructions
      relatives au paiement de la différence de prix.
    </p>
    <p>
      Sauf indication contraire, les prix sont indiqués en Euros, toutes taxes
      comprises.
    </p>
    <p>
      Pour les commandes hors Union Européenne, le prix de vente correspond au
      prix Hors Taxe auquel il faudra ajouter les frais et taxes d'entrées sur
      le territoire qui seront à la charge du Client.
    </p>
    <p>
      Une facture est établie par le Vendeur et remise au Client lors de la
      livraison des Produits commandés.
    </p>
    <h3>ARTICLE 3 - Commandes</h3>
    <p>
      Il appartient au Client de sélectionner sur le site www.sunjewelry.fr les
      Produits qu'il désire commander, selon les modalités suivantes :
    </p>
    <ul>
      <li>
        Le Client choisit un article qu'il met dans son panier, article qu'il
        pourra modifier ou supprimer avant de valider sa commande et d'accepter
        les présentes conditions générales de vente.
      </li>
      <li>
        Le Client rentre ensuite ses coordonnées ou se connecte à son espace et
        valide les modalités de livraison.
      </li>
      <li>
        Après validation des informations et du prix total de la commande, le
        Client suit les instructions du serveur de paiement en ligne pour payer
        le prix total de la commande.
      </li>
      <li>
        Le Client reçoit ensuite une confirmation d'acceptation de paiement de
        la commande.
      </li>
      <li>
        La Livraison aura lieu à l'adresse de livraison indiquée par le Client
        lors de la commande.
      </li>
    </ul>
    <p>
      Les offres de Produits sont valables tant qu'elles sont visibles sur le
      site.
    </p>
    <p>
      Chaque pièce est fabriquée à la commande, le Vendeur n'a pas de stock. Les
      délais de conception peuvent donc varier de 3 à 6 semaines.
    </p>
    <p>
      La vente ne sera considérée comme valide qu'après paiement intégral du
      prix. Il appartient au Client de vérifier l'exactitude de la commande et
      de signaler immédiatement toute erreur.
    </p>
    <p>
      Toute commande passée sur le site www.sunjewelry.fr constitue la formation
      d'un contrat conclu à distance entre le Client et le Vendeur.
    </p>
    <p>
      Le Vendeur se réserve le droit d'annuler ou de refuser toute commande d'un
      Client avec lequel il existerait un litige relatif au paiement d'une
      commande antérieure.
    </p>
    <p>
      Toute annulation de la commande par le Client après son acceptation par le
      Vendeur ne sera possible que tant que le colis n'a pas été expédié par le
      Vendeur (indépendamment des dispositions relatives à l'application ou non
      du droit de rétractation légal).
    </p>
    <h3>ARTICLE 4 - Conditions de paiement</h3>
    <p>
      Le prix est payé par voie de paiement sécurisé, selon les modalités
      suivantes : carte de paiement (CB, Visa, Mastercard, American Express)
    </p>
    <p>
      Le prix est payable comptant par le Client, en totalité au jour de la
      passation de la commande.
    </p>
    <p>
      Les données de paiement sont échangées en mode crypté grâce au protocole
      défini par le prestataire de paiement agréé intervenant pour les
      transactions bancaires réalisées sur le site www.sunjewelry.fr
    </p>
    <p>
      Les paiements effectués par le Client ne seront considérés comme
      définitifs qu'après encaissement effectif par le Vendeur des sommes dues.
    </p>
    <p>
      Le Vendeur ne sera pas tenu de procéder à la délivrance des Produits
      commandés par le Client si celui-ci ne lui en paye pas le prix en totalité
      dans les conditions ci-dessus indiquées.
    </p>
    <h3>ARTICLE 5 - Livraisons</h3>
    <p>
      Les Produits commandés par le Client seront livrés en France
      métropolitaine ou dans quelque pays que ce soit, sauf indication
      contraire.
    </p>
    <p>
      Les délais de production varient en fonction de chaque article. Les délais
      de livraison sont estimés à 72h une fois la production des articles
      finalisée, à l'adresse indiquée par le Client lors de sa commande sur le
      site.
    </p>
    <p>
      La livraison est constituée par le transfert au Client de la possession
      physique ou du contrôle du Produit. Sauf cas particulier ou
      indisponibilité d'un ou plusieurs Produits, les Produits commandés seront
      livrés en une seule fois.
    </p>
    <p>
      Le Vendeur s'engage à faire ses meilleurs efforts pour livrer les produits
      commandés par le Client dans les délais ci-dessus précisés.
    </p>
    <p>En cas de retard de Livraison, la Commande n'est pas annulée.</p>
    <p>
      Le Vendeur informera le Client par courrier électronique que la Livraison
      interviendra avec du retard. La vente pourra être résolue à la demande
      écrite du Client dans les conditions prévues aux articles L 216-2, L 216-3
      et L241-4 du Code de la consommation. Les sommes versées par le Client lui
      seront alors restituées au plus tard dans les quatorze jours qui suivent
      la date de dénonciation du contrat, à l'exclusion de toute indemnisation
      ou retenue.
    </p>
    <p>
      Les livraisons sont assurées par un transporteur indépendant, à l'adresse
      mentionnée par le Client lors de la commande et à laquelle le transporteur
      pourra facilement accéder.
    </p>
    <p>
      Lorsque le Client s'est lui-même chargé de faire appel à un transporteur
      qu'il choisit lui-même, la livraison est réputée effectuée dès la remise
      des Produits commandés par le Vendeur au transporteur qui les a acceptés
      sans réserve. Le Client reconnaît donc que c'est au transporteur qu'il
      appartient d'effectuer la livraison et ne dispose d'aucun recours en
      garantie contre le Vendeur en cas de défaut de livraison des marchandises
      transportées.
    </p>
    <p>
      En cas de demande particulière du Client concernant les conditions
      d'emballage ou de transport des produits commandés, dûment acceptées par
      écrit par le Vendeur, les coûts y liés feront l'objet d'une facturation
      spécifique complémentaire, sur devis préalablement accepté par écrit par
      le Client.
    </p>
    <p>
      Le Client est tenu de vérifier l'état des produits livrés. Il dispose d'un
      délai de quatorze jours calendaires à compter de la livraison pour
      formuler des réclamations par voie électronique à l'adresse
      contact@sunjewelry.fr en rappelant la référence et la date de la Commande,
      accompagnées de tous les justificatifs y afférents (photos notamment).
      Passé ce délai et à défaut d'avoir respecté ces formalités, les Produits
      seront réputés conformes et exempts de tout vice apparent et aucune
      réclamation ne pourra être valablement acceptée par le Vendeur.
    </p>
    <p>
      Le Vendeur remboursera ou remplacera dans les plus brefs délais et à ses
      frais, les Produits livrés dont les défauts de conformité ou les vices
      apparents ou cachés auront été dûment prouvés par le Client, dans les
      conditions prévues aux articles L 217-4 et suivants du Code de la
      consommation et celles prévues aux présentes CGV.
    </p>
    <p>
      Le transfert des risques de perte et de détérioration s'y rapportant, ne
      sera réalisé qu'au moment où le Client prendra physiquement possession des
      Produits. Les Produits voyagent donc aux risques et périls du Vendeur sauf
      lorsque le Client aura lui-même choisi le transporteur. A ce titre, les
      risques sont transférés au moment de la remise du bien au transporteur.
    </p>
    <h3>ARTICLE 6 - Transfert de propriété</h3>
    <p>
      Le transfert de propriété des Produits du Vendeur au Client ne sera
      réalisé qu'après complet paiement du prix par ce dernier, et ce quelle que
      soit la date de livraison desdits Produits.
    </p>
    <h3>ARTICLE 7 - Droit de rétractation</h3>
    <p>
      Selon les modalités de l'article L221-18 du Code de la Consommation
      <span class="italic"
        >«Le consommateur dispose d'un délai de quatorze jours pour exercer son
        droit de rétractation d'un contrat conclu à distance, à la suite d'un
        démarchage téléphonique ou hors établissement, sans avoir à motiver sa
        décision ni à supporter d'autres coûts que ceux prévus aux articles L.
        221-23 à L. 221-25.</span
      >
    </p>
    <p class="italic">
      Le délai mentionné au premier alinéa court à compter du jour :
    </p>
    <p class="italic">
      1° De la conclusion du contrat, pour les contrats de prestation de
      services et ceux mentionnés à l'article L. 221-4 ;
    </p>
    <p class="italic">
      2° De la réception du bien par le consommateur ou un tiers, autre que le
      transporteur, désigné par lui, pour les contrats de vente de biens. Pour
      les contrats conclus hors établissement, le consommateur peut exercer son
      droit de rétractation à compter de la conclusion du contrat.
    </p>
    <p class="italic">
      Dans le cas d'une commande portant sur plusieurs biens livrés séparément
      ou dans le cas d'une commande d'un bien composé de lots ou de pièces
      multiples dont la livraison est échelonnée sur une période définie, le
      délai court à compter de la réception du dernier bien ou lot ou de la
      dernière pièce.
    </p>
    <p class="italic">
      Pour les contrats prévoyant la livraison régulière de biens pendant une
      période définie, le délai court à compter de la réception du premier bien.
      »
    </p>
    <p>
      Le droit de rétractation peut être exercé en ligne, à l'aide du formulaire
      de rétractation ci-joint et également disponible sur le site ou de toute
      autre déclaration, dénuée d'ambiguïté, exprimant la volonté de se
      rétracter et notamment par courrier postal adressé au Vendeur aux
      coordonnées postales ou mail indiquées à l'ARTICLE 1 des CGV.
    </p>
    <p>
      Les retours sont à effectuer dans leur état d'origine et complets
      (emballage, accessoires, notice…) permettant leur recommercialisation à
      l'état neuf, accompagnés de la facture d'achat.
    </p>
    <p>Les Produits endommagés, salis ou incomplets ne sont pas repris.</p>
    <p>
      Un bijou personnalisé, créé sur-mesure ou gravé ne peut pas faire l'objet
      d'un retour.
    </p>
    <p>Les frais de retour restent à la charge du Client.</p>
    <p>
      Le remboursement sera effectué dans un délai de 14 jours à compter de la
      réception, par le Vendeur, des Produits retournés par le Client dans les
      conditions prévues au présent article.
    </p>
    <p>
      Pour toute commande hors Union Européenne, les frais de livraison de la
      commande, les frais de retour des articles commandés ainsi que toutes
      taxes de douanes et frais relatifs demeurent à la charge du Client,
      lesquels seront déduits du prix des articles retournés en cas de
      remboursement.
    </p>
    <h3>ARTICLE 8 - Responsabilité du Vendeur - Garanties</h3>
    <p>Les Produits fournis par le Vendeur bénéficient :</p>
    <ul>
      <li>
        de la garantie légale de conformité, pour les Produits défectueux,
        abîmés ou endommagés ou ne correspondant pas à la commande,
      </li>
      <li>
        de la garantie légale contre les vices cachés provenant d'un défaut de
        matière, de conception ou de fabrication affectant les produits livrés
        et les rendant impropres à l'utilisation,
      </li>
    </ul>
    <p>Dispositions relatives aux garanties légales</p>
    <p>Article L217-4 du Code de la consommation</p>
    <p class="italic">
      « Le vendeur est tenu de livrer un bien conforme au contrat et répond des
      défauts de conformité existant lors de la délivrance. Il répond également
      des défauts de conformité résultant de l'emballage, des instructions de
      montage ou de l'installation lorsque celle-ci a été mise à sa charge par
      le contrat ou a été réalisée sous sa responsabilité. »
    </p>
    <p>Article L217-5 du Code de la consommation</p>
    <p class="italic">« Le bien est conforme au contrat :</p>
    <p class="italic">
      1° S'il est propre à l'usage habituellement attendu d'un bien semblable
      et, le cas échéant :
    </p>
    <p class="italic">
      - s'il correspond à la description donnée par le vendeur et possède les
      qualités que celui-ci a présentées à l'acheteur sous forme d'échantillon
      ou de modèle ;
    </p>
    <p class="italic">
      - s'il présente les qualités qu'un acheteur peut légitimement attendre eu
      égard aux déclarations publiques faites par le vendeur, par le producteur
      ou par son représentant, notamment dans la publicité ou l'étiquetage ;
    </p>
    <p class="italic">
      2° Ou s'il présente les caractéristiques définies d'un commun accord par
      les parties ou est propre à tout usage spécial recherché par l'acheteur,
      porté à la connaissance du vendeur et que ce dernier a accepté. »
    </p>
    <p>Article L217-12 du Code de la consommation</p>
    <p class="italic">
      « L'action résultant du défaut de conformité se prescrit par deux ans à
      compter de la délivrance du bien. »
    </p>
    <p>Article 1641 du Code civil.</p>
    <p class="italic">
      « Le vendeur est tenu de la garantie à raison des défauts cachés de la
      chose vendue qui la rendent impropre à l'usage auquel on la destine, ou
      qui diminuent tellement cet usage, que l'acheteur ne l'aurait pas acquise,
      ou n'en aurait donné qu'un moindre prix, s'il les avait connus. »
    </p>
    <p>Article 1648 alinéa 1er du Code civil</p>
    <p class="italic">
      « L'action résultant des vices rédhibitoires doit être intentée par
      l'acquéreur dans un délai de deux ans à compter de la découverte du vice.
      »
    </p>
    <p>Article L217-16 du Code de la consommation.</p>
    <p class="italic">
      « Lorsque l'acheteur demande au vendeur, pendant le cours de la garantie
      commerciale qui lui a été consentie lors de l'acquisition ou de la
      réparation d'un bien meuble, une remise en état couverte par la garantie,
      toute période d'immobilisation d'au moins sept jours vient s'ajouter à la
      durée de la garantie qui restait à courir. Cette période court à compter
      de la demande d'intervention de l'acheteur ou de la mise à disposition
      pour réparation du bien en cause, si cette mise à disposition est
      postérieure à la demande d'intervention. »
    </p>
    <p>
      Afin de faire valoir ses droits, le Client devra informer le Vendeur, par
      écrit (e-mail ou courrier), de la non-conformité des Produits ou de
      l'existence des vices cachés à compter de leur découverte.
    </p>
    <p>
      Le Vendeur remboursera, remplacera ou fera réparer les Produits ou pièces
      sous garantie jugés non conformes ou défectueux.
    </p>
    <p>
      Les frais d'envoi seront remboursés sur la base du tarif facturé et les
      frais de retour seront remboursés sur présentation des justificatifs.
    </p>
    <p>
      Les remboursements, remplacements ou réparations des Produits jugés non
      conformes ou défectueux seront effectués dans les meilleurs délais suivant
      la constatation par le Vendeur du défaut de conformité ou du vice caché.
    </p>
    <p>
      La responsabilité du Vendeur ne saurait être engagée dans les cas suivants
      :
    </p>
    <ul>
      <li>
        non respect de la législation du pays dans lequel les produits sont
        livrés, qu'il appartient au Client de vérifier,
      </li>
      <li>
        en cas de mauvaise utilisation, d'utilisation à des fins
        professionnelles, négligence ou défaut d'entretien de la part du Client,
        comme en cas d'usure normale du Produit, d'accident ou de force majeure.
      </li>
      <li>
        Les photographies et graphismes présentés sur le site ne sont pas
        contractuels et ne sauraient engager la responsabilité du Vendeur.
      </li>
    </ul>
    <p>
      La garantie du Vendeur est, en tout état de cause, limitée au remplacement
      ou au remboursement des Produits non conformes ou affectés d'un vice.
    </p>
    <h3>ARTICLE 9 - Données personnelles</h3>
    <h4>9.1 Collecte des données à caractère personnel</h4>
    <p>
      Les données à caractère personnel qui sont collectées sur le site
      www.sunjewelry.fr sont les suivantes :
    </p>
    <ul>
      <li>
        Les données relatives à la création d'un compte personnel et à la
        commande de produits sur le Site : noms, prénoms, adresse postale,
        numéro de téléphone et adresse e-mail
      </li>
      <li>
        Les données relatives au paiement. Le paiement est entièrement sécurisé
        sur le site www.sunjewelry.fr grâce au système de paiement en ligne du
        partenaire de paiement Stripe, solution de paiement certifiée PCI/DSS de
        niveau 1 (le niveau de sécurité le plus élevé pour payer en ligne). Les
        données bancaires sont cryptées. De plus, elles ne sont traitées que par
        Stripe et ne sont jamais en la possession du Vendeur.
      </li>
      <li>
        Les données relatives à la navigation sur le Site : adresse IP, détails
        de connexion, détails des recherches de produits et des commandes,
        détails de géolocalisation et autres cookies permettant une gestion du
        fonctionnement et optimisation du Site
      </li>
    </ul>
    <p>Le site recourt à l'usage de cookies.</p>
    <p>
      Un cookie ou traceur est un fichier électronique stocké dans le système
      informatique de l'Utilisateur.
    </p>
    <p>
      L'accès et l'utilisation du Site par l'Utilisateur entraînent le dépôt et
      la lecture de cookies qui permettent principalement d'optimiser et de
      personnaliser l'utilisation du Site ainsi que de connaître les
      performances du Site, pendant la durée de validité ou d'enregistrement du
      cookie.
    </p>
    <p>
      En poursuivant la navigation sur le Site, l'Utilisateur accepte
      l'utilisation des cookies nécessaires à la poursuite des finalités
      susvisées.
    </p>
    <p>
      L'Utilisateur dispose, par le biais de son navigateur internet, du droit
      de s'opposer au dépôt et à la lecture de ces cookies et à l'analyse de sa
      navigation.
    </p>
    <h4>9.2 Destinataires des données à caractère personnel</h4>
    <p>
      Les données à caractère personnel sont utilisées par le Vendeur et ses
      co-contractants pour l'exécution du contrat et pour assurer l'efficacité
      de la prestation de vente et de délivrance des Produits.
    </p>
    <p>Les catégories de co-contractants sont :</p>
    <ul>
      <li>prestataires de transport</li>
      <li>prestataires établissements de paiement</li>
    </ul>
    <h4>9.3 Responsable de traitement</h4>
    <p>
      Le responsable de traitement des données est le Vendeur, au sens de la loi
      Informatique et libertés et à compter du 25 mai 2018 du Règlement 2016/679
      sur la protection des données à caractère personnel.
    </p>
    <h4>9.4 Limitation du traitement</h4>
    <p>
      Sauf si le Client exprime son accord exprès, ses données à caractère
      personnelles ne sont pas utilisées à des fins publicitaires ou marketing.
    </p>
    <h4>9.5 Durée de conservation des données</h4>
    <p>
      Le Vendeur conservera les données ainsi recueillies pendant un délai de 5
      ans, couvrant le temps de la prescription de la responsabilité civile
      contractuelle applicable.
    </p>
    <h4>9.6 Sécurité et confidentialité</h4>
    <p>
      Le Vendeur met en œuvre des mesures organisationnelles, techniques,
      logicielles et physiques en matière de sécurité du numérique pour protéger
      les données personnelles contre les altérations, destructions et accès non
      autorisés. Toutefois il est à signaler qu'Internet n'est pas un
      environnement complètement sécurisé et le Vendeur ne peut garantir la
      sécurité de la transmission ou du stockage des informations sur Internet.
    </p>
    <h4>9.7 Mise en œuvre des droits des Clients et utilisateurs</h4>
    <p>
      En application de la règlementation applicable aux données à caractère
      personnel, les Clients et utilisateurs du site www.sunjewelry.fr disposent
      des droits suivants :
    </p>
    <ul>
      <li>
        Ils peuvent mettre à jour ou supprimer les données qui les concernent
      </li>
      <li>Ils peuvent supprimer leur compte</li>
      <li>
        Ils peuvent exercer leur droit d'accès pour connaître les données
        personnelles les concernant
      </li>
      <li>
        Si les données à caractère personnel détenues par le Vendeur sont
        inexactes, ils peuvent demander la mise à jour des informations des
        informations
      </li>
      <li>
        Ils peuvent demander la suppression de leurs données à caractère
        personnel, conformément aux lois applicables en matière de protection
        des données
      </li>
      <li>
        Ils peuvent également solliciter la portabilité des données détenues par
        le Vendeur vers un autre prestataire
      </li>
      <li>
        Enfin, ils peuvent s'opposer au traitement de leurs données par le
        Vendeur
      </li>
    </ul>
    <p>
      Ces droits, dès lors qu'ils ne s'opposent pas à la finalité du traitement,
      peuvent être exercé en écrivant à l'adresse électronique
      contact@sunjewelry.fr
    </p>
    <p>
      Le responsable de traitement doit apporter une réponse dans un délai
      maximum d'un mois.
    </p>
    <p>
      En cas de refus de faire droit à la demande du Client, celui-ci doit être
      motivé.
    </p>
    <p>
      Le Client est informé qu'en cas de refus, il peut introduire une
      réclamation auprès de la CNIL (3 place de Fontenoy, 75007 PARIS) ou saisir
      une autorité judiciaire.
    </p>
    <p>
      Le Client peut être invité à cocher une case au titre de laquelle il
      accepte de recevoir des mails à caractère informatifs et publicitaires de
      la part du Vendeur. Il aura toujours la possibilité de retirer son accord
      à tout moment en contactant le Vendeur (coordonnées ci-dessus) ou en
      suivant le lien de désabonnement.
    </p>
    <h3>ARTICLE 10 - Propriété intellectuelle</h3>
    <p>
      Le contenu du site www.sunjewelry.fr est la propriété du Vendeur et de ses
      partenaires et est protégé par les lois françaises et internationales
      relatives à la propriété intellectuelle.
    </p>
    <p>
      Toute reproduction totale ou partielle de ce contenu est strictement
      interdite et est susceptible de constituer un délit de contrefaçon.
    </p>
    <h3>ARTICLE 11 - Droit applicable - Langue</h3>
    <p>
      Les présentes CGV et les opérations qui en découlent sont régies et
      soumises au droit français.
    </p>
    <p>
      Les présentes CGV sont rédigées en langue française. Dans le cas où elles
      seraient traduites en une ou plusieurs langues étrangères, seul le texte
      français ferait foi en cas de litige.
    </p>
    <h3>ARTICLE 12 - Litiges</h3>
    <p>
      Pour toute réclamation merci de contacter le service clientèle à l'adresse
      postale ou e-mail du Vendeur indiquée à l'ARTICLE 1 des présentes CGV.
    </p>
    <p>
      Le Client est informé qu'il peut en tout état de cause recourir à une
      médiation conventionnelle, auprès des instances de médiation sectorielles
      existantes ou à tout mode alternatif de règlement des différends
      (conciliation, par exemple) en cas de contestation.
    </p>
    <p>
      La Commission européenne fournit une plateforme de règlement des litiges
      en ligne (OS). Cette plateforme est disponible à l'adresse
      http://ec.europa.eu/consumers/odr/.
    </p>
    <p>
      En tant que client, vous avez la possibilité de contacter le conseil
      d'arbitrage de la Commission européenne. Le Vendeur n'est ni disposés à,
      ni obligés de, participer à une procédure de règlement des litiges devant
      un conseil d'arbitrage de la consommation.
    </p>
    <p>
      Tous les litiges auxquels les opérations d'achat et de vente conclues en
      application des présentes CGV et qui n'auraient pas fait l'objet d'un
      règlement amiable entre le vendeur ou par médiation, seront soumis aux
      tribunaux compétents dans les conditions de droit commun.
    </p>
    <p>
      Tous les litiges auxquels les opérations d'achat et de vente conclues en
      application des présentes CGV et qui n'auraient pas fait l'objet d'un
      règlement amiable entre le vendeur ou par médiation, seront soumis aux
      tribunaux compétents dans les conditions de droit commun.
    </p>
    <h2>ANNEXE 1</h2>
    <p>Formulaire de rétractation</p>
    <p>Date ______________________</p>
    <p>
      Le présent formulaire doit être complété et renvoyé uniquement si le
      Client souhaite se rétracter de la commande passée sur www.sunjewelry.fr
      sauf exclusions ou limites à l'exercice du droit de rétractation suivant
      les Conditions Générales de Vente applicables.
    </p>
    <p>A l'attention de Typhen LE ROMANCER - SUN Jewelry</p>
    <p>2 rue de l'oiselère, 69009 Lyon</p>
    <p>
      Je notifie par la présente la rétractation du contrat portant sur le bien
      ci-dessous :
    </p>
    <p>- Commande du (indiquer la date)</p>
    <p>- Numéro de la commande :</p>
    <p>- Nom du Client :</p>
    <p>- Adresse du Client :</p>
    <p>
      Signature du Client (uniquement en cas de notification du présent
      formulaire sur papier)
    </p>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "CGV",
    link: [{ rel: "canonical", href: "https://sunjewelry.fr/cgv" }],
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 5% 10%;

  .firstTitle {
    padding-top: 3%;
  }

  .italic {
    font-style: italic;
  }
}
</style>